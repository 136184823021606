<div class="container-fluid footer">
	<div class="container">
		<div class="row">
			<div class="col-3" style="text-align: center">
				<img src="assets/img/KTK.png" width="90%">
				<img src="assets/img/UK_development.jpg" width="94%" style="margin-top: 40px">
			</div>
			<div class="col-6 centerItems" style="text-align:justify;">
			{{"Оваа веб страна е продуцирана со финансиска поддршка на UN Women. За содржината е одговорен авторот на веб страната и во никој случај не ги рефлектира позициите на Агенцијата на Обединетите Нации за родова еднаквост и зајакнување на жените (UN Women), Обединетите Нации или придружни организации."|translate}} <br><br>
			{{"Во 2024 година, оваа веб страница е ажурирана во рамки на проектот „Безбедност на жените во акција: Градење родово инклузивни јавни простори“. Проектот е финансиран/ко-финансиран од: логото на КТК и УК Овој материјал е целосно или делумно финансиран од UK International Development и Фондацијата Kvinna till Kvinna, кои не мора да се согласуваат со мислењата изразени во него. За содржината е одговорен авторот."|translate}}
			
			</div>
			

			<div class="col-3" style="text-align: center">
				<img src="assets/img/unwomen.png">
				<img src='{{"assets/img/reactorMk.png"|translate}}' style="margin-top: 40px">
			</div>
		</div>
	</div>
</div>